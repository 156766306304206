<template>
	<div>
    <Loader v-if="(!nYUsers || (nYUsers && nYUsers.length == 0))" />
    <div class="flex align-start flex-wrap mt-2">
      <a href="`https://jumpstaffing.com/nytracker/" target="_blank">
        <button class="btn btn__outlined mr-3 mb-2">NY Tracker <i class="fa-solid fa-up-right-from-square ml-2"></i></button>
      </a>
      <button class="btn btn__outlined mr-3 mb-2" @click="resendInvites()">Resend Background Checks
        <transition name="fade">
          <span class="ml-2" v-if="performingRequest2">
          <i class="fa fa-spinner fa-spin"></i>
          </span>
        </transition>
      </button>
      <button class="btn btn__outlined mr-3 mb-2" @click="resendReminders()">Resend Reminders
        <transition name="fade">
          <span class="ml-2" v-if="performingRequest">
          <i class="fa fa-spinner fa-spin"></i>
          </span>
        </transition>
      </button>
      <button class="btn btn__outlined mr-3 mb-2" @click="exportReport()">Export
        <transition name="fade">
          <span class="ml-2" v-if="performingRequest3">
          <i class="fa fa-spinner fa-spin"></i>
          </span>
        </transition>
      </button>
    </div>
    <div class="dashboard__container--header flex justify-space-between align-end">
        <div class="flex align-start flex-column">
          <div class="mt-0 mb-0">
            Total Applications: <span v-if="totalApplications">{{totalApplications}}</span>
          </div>
          <div class="mt-0 mb-0">
            SSN Review: <span v-if="underReview">{{underReview.length}}</span>
          </div>
          <div class="mt-0 mb-0">
            Total Passed: <span v-if="totalPassed">{{totalPassed.length}}</span>
          </div>
          <div class="mt-0 mb-3">
            Total Failed: <span v-if="totalFailed">{{totalFailed.length}}</span>
          </div>
        </div>
      </div>
   	<div class="dashboard__container--body--col pt-3 mb-0" style="width:100%;" v-if="nYUsers && nYUsers.length > 0">
      <UserTable :groupUsers="nYUsers" action="removeUser" :showShiftPreference="true" :isNewYork="true" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import UserTable from '@/components/UserTable.vue'
import ExportService from "@/services/ExportService"
import router from '@/router'
const fb = require('../../firebaseConfig.js')
import * as moment from 'moment'

export default {
  name: 'newYork',
  components: {
    Loader,
    UserTable
  },
  data() {
    return {
      performingRequest: false,
      performingRequest2: false,
      performingRequest3: false,
    }
  },
  computed: {
    ...mapState(['nYUsers']),
    totalApplications() {
      return this.nYUsers.length
    },
    totalPending() {
      return this.nYUsers.filter(user => (
        user &&
        user.checkInvitations &&
        user.checkInvitations[0] &&
        user.checkInvitations[0].payload &&
        user.checkInvitations[0].payload.result &&
        user.checkInvitations[0].payload.result.invitationID && 
        !user.backgroundCheckResponse
      ));
    },
    totalPassed() {
      return this.nYUsers.filter(user => (
        user &&
        user.backgroundCheckResponse &&
        user.backgroundCheckResponse == 'Passed'
      ));
    },
    underReview() {
       return this.nYUsers.filter(user => (
        user &&
        user.backgroundCheckResponse &&
        user.backgroundCheckResponse == 'Needs Review'
      ));
    },
    totalFailed() {
      return this.nYUsers.filter(user => (
        user &&
        user.backgroundCheckResponse &&
        user.backgroundCheckResponse == 'Failed Do Not Hire'
      ));
    },
    // totalUnderReview() {
    //   return this.nYUsers.filter(user => (
    //     user &&
    //     user.checkOrderResults &&
    //     user.checkOrderResults[0] &&
    //     user.checkOrderResults[0].description &&
    //     user.checkOrderResults[0].description == 'Orders returned' && 
    //     !user.backgroundCheckResponse
    //   ));
    // },



    
  },
  methods:{
    async resendInvites() {
      this.performingRequest2 = true
      await this.nYUsers.forEach(user => {
        if (
          user &&
          user.checkInvitations &&
          user.checkInvitations[0] &&
          user.checkInvitations[0].payload &&
          user.checkInvitations[0].payload.result &&
          user.checkInvitations[0].payload.result.invitationID && 
          !user.backgroundCheckResponse
          ) {
          console.log(user.checkInvitations[0].payload.result.invitationID)
          let check = {
            ID: user.checkInvitations[0].payload.result.invitationID,
            ApplicantName: user.firstName + ' ' + user.lastName,
            ApplicantEmail: user.email,
            ApplicantCell: user.phone,
            userId: user.id
          }
          this.$store.dispatch("resendInvitation", check)
        }
      })
      this.performingRequest2 = false
    },
    async resendReminders() {
      this.performingRequest = true
      await this.nYUsers.forEach(user => {
        if (
          user &&
          user.checkInvitations &&
          user.checkInvitations[0] &&
          user.checkInvitations[0].payload &&
          user.checkInvitations[0].payload.result &&
          user.checkInvitations[0].payload.result.invitationID && 
          !user.backgroundCheckResponse
          ) {
          let newText = {
            name: user.firstName,
            phone: user.phone,
            userId: user.id,
            type: 'backgroundReminder'
          }
          this.$store.dispatch('updateBackgroundReminder', newText)
        }
      })
      this.performingRequest = false
    },

    exportReport() {
      this.performingRequest3 = true
      const exportHeaders = [
        "First Name",
        "Last Name",
        "Status",
        "locationPreference",
        "email",
        "status",
      ];
      const exportItems = [];
      for (var key in this.nYUsers) {

        // if (this.nYUsers[key].checkOrderResults && this.nYUsers[key].checkOrderResults[0] && this.nYUsers[key].checkOrderResults[0].payload && this.nYUsers[key].checkOrderResults[0].payload.Searches && this.nYUsers[key].checkOrderResults[0].payload.Searches[0]) {
        //   ofac1 = this.nYUsers[key].checkOrderResults[0].payload.Searches[0].SearchStatus + ' ' + this.nYUsers[key].checkOrderResults[0].payload.Searches[0].RecordsFound
        // }
        // if (this.nYUsers[key].checkOrderResults && this.nYUsers[key].checkOrderResults[0] && this.nYUsers[key].checkOrderResults[0].payload && this.nYUsers[key].checkOrderResults[0].payload.Searches && this.nYUsers[key].checkOrderResults[0].payload.Searches[0]) {
        //   ssn1 = this.nYUsers[key].checkOrderResults[0].payload.Searches[1].SearchStatus + ' ' + this.nYUsers[key].checkOrderResults[0].payload.Searches[1].RecordsFound
        // }
        // if (this.nYUsers[key].checkOrderResults && this.nYUsers[key].checkOrderResults[0] && this.nYUsers[key].checkOrderResults[0].payload && this.nYUsers[key].checkOrderResults[0].payload.Searches && this.nYUsers[key].checkOrderResults[0].payload.Searches[0]) {
        //   so1 = this.nYUsers[key].checkOrderResults[0].payload.Searches[2].SearchStatus + ' ' + this.nYUsers[key].checkOrderResults[0].payload.Searches[2].RecordsFound
        // }

        // if (this.nYUsers[key].checkOrderResults && this.nYUsers[key].checkOrderResults[1] && this.nYUsers[key].checkOrderResults[1].payload && this.nYUsers[key].checkOrderResults[1].payload.Searches && this.nYUsers[key].checkOrderResults[1].payload.Searches[0]) {
        //   ofac2 = this.nYUsers[key].checkOrderResults[1].payload.Searches[0].SearchStatus + ' ' + this.nYUsers[key].checkOrderResults[1].payload.Searches[0].RecordsFound
        // }
        // if (this.nYUsers[key].checkOrderResults && this.nYUsers[key].checkOrderResults[1] && this.nYUsers[key].checkOrderResults[1].payload && this.nYUsers[key].checkOrderResults[1].payload.Searches && this.nYUsers[key].checkOrderResults[1].payload.Searches[0]) {
        //   ssn2 = this.nYUsers[key].checkOrderResults[1].payload.Searches[1].SearchStatus + ' ' + this.nYUsers[key].checkOrderResults[1].payload.Searches[1].RecordsFound
        // }


          



        
      

      exportItems.push([
            this.nYUsers[key].firstName,
            this.nYUsers[key].lastName,
            this.nYUsers[key].backgroundCheckResponse || null,
            this.nYUsers[key].locationPreference,
            this.nYUsers[key].email,
            this.nYUsers[key].backgroundCheckResponse,
          ]);

        
          this.$gapi.getGapiClient().then(gapi => {
            const exportService = new ExportService(exportHeaders, Object.values(exportItems), gapi);
            exportService.export();
          });
          setTimeout(() => {
            this.performingRequest3 = false
          }, 2000)
      }
      
    },
  },
  created () {
    this.$store.dispatch("getNYUsers");
  },
  beforeDestroy () {
    this.$store.dispatch("clearNYUsers");
  },
}
    
</script>